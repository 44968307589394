import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Upload, X } from 'lucide-react';
import { ServiceRecord } from './ServiceHistory';

interface ServiceFormProps {
  onSubmit: (data: Partial<ServiceRecord>) => void;
  onCancel: () => void;
  initialData?: ServiceRecord | null;
}

const serviceTypes = [
  'Oil Change',
  'Tire Rotation',
  'Brake Service',
  'Air Filter',
  'Transmission',
  'Battery',
  'Other',
];

export default function ServiceForm({ onSubmit, onCancel, initialData }: ServiceFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Partial<ServiceRecord>>({
    defaultValues: initialData || {
      serviceDate: new Date().toISOString().split('T')[0],
    },
  });

  const [attachment, setAttachment] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setAttachment(file);
    }
  };

  const removeAttachment = () => {
    setAttachment(null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Service Date</label>
        <input
          type="date"
          {...register('serviceDate', { required: 'Service date is required' })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
        {errors.serviceDate && (
          <p className="mt-1 text-sm text-red-600">{errors.serviceDate.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Service Type</label>
        <select
          {...register('serviceType', { required: 'Service type is required' })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="">Select a service type</option>
          {serviceTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        {errors.serviceType && (
          <p className="mt-1 text-sm text-red-600">{errors.serviceType.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Service Notes</label>
        <textarea
          {...register('serviceNotes', { required: 'Service notes are required' })}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter service details, parts replaced, etc."
        />
        {errors.serviceNotes && (
          <p className="mt-1 text-sm text-red-600">{errors.serviceNotes.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Attachment (Invoice/Receipt)
        </label>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            {attachment ? (
              <div className="flex items-center justify-center space-x-2">
                <span className="text-sm text-gray-600">{attachment.name}</span>
                <button
                  type="button"
                  onClick={removeAttachment}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <>
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                    <span>Upload a file</span>
                    <input
                      type="file"
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={handleFileChange}
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PDF, PNG, JPG up to 10MB</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
        >
          {isSubmitting
            ? 'Saving...'
            : initialData
            ? 'Update Service Record'
            : 'Add Service Record'}
        </button>
      </div>
    </form>
  );
}