import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { VehicleBudgetSummary } from '../../types/budget';
import { format } from 'date-fns';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 30,
    textAlign: 'center',
    color: '#666666',
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 10,
    color: '#333333',
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
  },
  tableCell: {
    width: '16.67%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 10,
  },
  summaryBox: {
    padding: 10,
    marginBottom: 20,
    backgroundColor: '#f8f9fa',
  },
  summaryText: {
    fontSize: 12,
    marginBottom: 5,
  },
});

interface BudgetPDFProps {
  summaries: VehicleBudgetSummary[];
}

const BudgetPDF: React.FC<BudgetPDFProps> = ({ summaries }) => {
  const totalAnnual = summaries.reduce((sum, s) => sum + s.annualTotal, 0);
  const monthlyAverage = totalAnnual / 12;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Budget Forecast Report</Text>
        <Text style={styles.subtitle}>
          Generated on {format(new Date(), 'MMMM d, yyyy')}
        </Text>

        <View style={styles.summaryBox}>
          <Text style={styles.summaryText}>
            Total Annual Budget: ${totalAnnual.toLocaleString()}
          </Text>
          <Text style={styles.summaryText}>
            Monthly Average: ${monthlyAverage.toLocaleString()}
          </Text>
          <Text style={styles.summaryText}>
            Number of Vehicles: {summaries.length}
          </Text>
        </View>

        {summaries.map((summary, index) => (
          <View key={index} style={styles.section}>
            <Text style={styles.sectionTitle}>{summary.vehicleName}</Text>
            
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={styles.tableCell}>Month</Text>
                <Text style={styles.tableCell}>Total</Text>
                <Text style={styles.tableCell}>Fuel</Text>
                <Text style={styles.tableCell}>Maintenance</Text>
                <Text style={styles.tableCell}>Insurance</Text>
                <Text style={styles.tableCell}>Depreciation</Text>
              </View>

              {summary.predictions.map((prediction, idx) => (
                <View key={idx} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{prediction.month}</Text>
                  <Text style={styles.tableCell}>
                    ${prediction.total.toLocaleString()}
                  </Text>
                  <Text style={styles.tableCell}>
                    ${prediction.fuel.toLocaleString()}
                  </Text>
                  <Text style={styles.tableCell}>
                    ${prediction.maintenance.toLocaleString()}
                  </Text>
                  <Text style={styles.tableCell}>
                    ${prediction.insurance.toLocaleString()}
                  </Text>
                  <Text style={styles.tableCell}>
                    ${prediction.depreciation.toLocaleString()}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default BudgetPDF;