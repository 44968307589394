import React from 'react';
import { VehicleLocation } from '../../types/mapping';
import VehicleStatusBadge from './VehicleStatusBadge';

interface VehicleListProps {
  vehicles: VehicleLocation[];
  selectedVehicle: VehicleLocation | null;
  onVehicleSelect: (vehicle: VehicleLocation) => void;
}

export default function VehicleList({ 
  vehicles, 
  selectedVehicle, 
  onVehicleSelect 
}: VehicleListProps) {
  return (
    <div className="lg:col-span-1 bg-white rounded-lg shadow-sm p-4 h-[70vh] overflow-y-auto">
      <h2 className="text-lg font-semibold mb-4">Vehicles</h2>
      <div className="space-y-4">
        {vehicles.map((vehicle) => (
          <div
            key={vehicle.id}
            className={`p-4 rounded-lg border cursor-pointer transition-colors ${
              selectedVehicle?.id === vehicle.id
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-200 hover:bg-gray-50'
            }`}
            onClick={() => onVehicleSelect(vehicle)}
          >
            <div className="flex items-start justify-between">
              <div>
                <h3 className="font-medium text-gray-900">{vehicle.vehicleName}</h3>
                <p className="text-sm text-gray-500">{vehicle.driver}</p>
              </div>
              <VehicleStatusBadge status={vehicle.status} />
            </div>
            <div className="mt-2 text-sm text-gray-600">
              <p>Speed: {vehicle.speed}</p>
              <p>Last Update: {vehicle.lastUpdate}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}