import React, { useState } from 'react';
import { format } from 'date-fns';
import { Calendar, FileText, Plus, Download, Pencil, X } from 'lucide-react';
import ServiceForm from './ServiceForm';
import { toast } from 'react-hot-toast';

export interface ServiceRecord {
  id: number;
  vehicleId: number;
  serviceDate: string;
  serviceType: string;
  serviceNotes: string;
  attachmentUrl?: string;
  attachmentName?: string;
}

interface ServiceHistoryProps {
  vehicleId: number;
}

// Mock data - replace with API calls
const mockServiceRecords: ServiceRecord[] = [
  {
    id: 1,
    vehicleId: 1,
    serviceDate: '2024-02-15',
    serviceType: 'Oil Change',
    serviceNotes: 'Regular maintenance - synthetic oil used',
    attachmentUrl: 'https://example.com/invoice1.pdf',
    attachmentName: 'invoice1.pdf',
  },
  {
    id: 2,
    vehicleId: 1,
    serviceDate: '2024-01-10',
    serviceType: 'Tire Rotation',
    serviceNotes: 'All tires rotated and balanced',
    attachmentUrl: 'https://example.com/invoice2.pdf',
    attachmentName: 'invoice2.pdf',
  },
];

export default function ServiceHistory({ vehicleId }: ServiceHistoryProps) {
  const [records] = useState<ServiceRecord[]>(
    mockServiceRecords.filter((record) => record.vehicleId === vehicleId)
  );
  const [showForm, setShowForm] = useState(false);
  const [editingRecord, setEditingRecord] = useState<ServiceRecord | null>(null);

  const handleSubmit = async (data: Partial<ServiceRecord>) => {
    try {
      if (editingRecord) {
        // Update existing record
        toast.success('Service record updated successfully');
      } else {
        // Add new record
        toast.success('Service record added successfully');
      }
      setShowForm(false);
      setEditingRecord(null);
    } catch (error) {
      toast.error('Failed to save service record');
    }
  };

  const handleEdit = (record: ServiceRecord) => {
    setEditingRecord(record);
    setShowForm(true);
  };

  const handleDownload = (url: string, filename: string) => {
    // Implement secure document download
    toast.success('Document downloaded successfully');
  };

  return (
    <div className="space-y-6">
      {/* Service History List */}
      <div className="bg-white shadow-sm rounded-lg">
        <div className="p-6 border-b border-gray-100">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold text-gray-900">Service History</h2>
            <button
              onClick={() => setShowForm(true)}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Service Record
            </button>
          </div>
        </div>

        <div className="divide-y divide-gray-200">
          {records.length === 0 ? (
            <div className="p-6 text-center text-gray-500">
              No service records found for this vehicle.
            </div>
          ) : (
            records.map((record) => (
              <div key={record.id} className="p-6 hover:bg-gray-50">
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    <div className="flex items-center">
                      <Calendar className="w-5 h-5 text-gray-400 mr-2" />
                      <h3 className="text-lg font-medium text-gray-900">
                        {record.serviceType}
                      </h3>
                    </div>
                    <div className="mt-1 text-sm text-gray-500">
                      {format(new Date(record.serviceDate), 'MMMM d, yyyy')}
                    </div>
                    <p className="mt-2 text-sm text-gray-600">{record.serviceNotes}</p>
                  </div>
                  <div className="flex items-center space-x-4">
                    {record.attachmentUrl && (
                      <button
                        onClick={() =>
                          handleDownload(record.attachmentUrl!, record.attachmentName!)
                        }
                        className="flex items-center text-gray-600 hover:text-gray-900"
                        title="Download attachment"
                      >
                        <Download className="w-5 h-5" />
                      </button>
                    )}
                    <button
                      onClick={() => handleEdit(record)}
                      className="flex items-center text-gray-600 hover:text-gray-900"
                      title="Edit record"
                    >
                      <Pencil className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                {record.attachmentName && (
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <FileText className="w-4 h-4 mr-1" />
                    {record.attachmentName}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>

      {/* Service Form Modal */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900">
                  {editingRecord ? 'Edit Service Record' : 'Add Service Record'}
                </h2>
                <button
                  onClick={() => {
                    setShowForm(false);
                    setEditingRecord(null);
                  }}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
              <ServiceForm
                onSubmit={handleSubmit}
                initialData={editingRecord}
                onCancel={() => {
                  setShowForm(false);
                  setEditingRecord(null);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}