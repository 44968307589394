// Mock data store for demonstration
export const mockAlerts = [
    {
      id: 1,
      type: 'maintenance',
      vehicle: '2022 Tesla Model 3',
      message: 'Scheduled maintenance due in 5 days',
      date: '2024-03-25',
      priority: 'high',
    },
    {
      id: 2,
      type: 'insurance',
      vehicle: '2021 Ford F-150',
      message: 'Insurance renewal required in 15 days',
      date: '2024-04-10',
      priority: 'medium',
    },
    {
      id: 3,
      type: 'registration',
      vehicle: '2023 Toyota Camry',
      message: 'Registration expires in 30 days',
      date: '2024-04-20',
      priority: 'medium',
    },
    {
      id: 4,
      type: 'maintenance',
      vehicle: '2022 Tesla Model 3',
      message: 'Tire rotation overdue',
      date: '2024-03-20',
      priority: 'high',
    },
    {
      id: 5,
      type: 'driver',
      vehicle: '2021 Ford F-150',
      message: "Driver's license expires in 10 days",
      date: '2024-03-30',
      priority: 'high',
    }
  ];
  
  // Helper function to get active alerts count
  export const getActiveAlertsCount = () => mockAlerts.length;