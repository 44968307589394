import { VehicleLocation } from '../types/mapping';

export const mockVehicleLocations: VehicleLocation[] = [
  {
    id: 1,
    vehicleName: '2022 Tesla Model 3',
    driver: 'John Doe',
    position: { lat: 40.7128, lng: -74.0060 },
    status: 'active',
    lastUpdate: '2 minutes ago',
    speed: '45 mph',
    destination: 'Central Park, New York',
  },
  {
    id: 2,
    vehicleName: '2021 Ford F-150',
    driver: 'Jane Smith',
    position: { lat: 40.7580, lng: -73.9855 },
    status: 'maintenance',
    lastUpdate: '5 minutes ago',
    speed: '0 mph',
    destination: 'Service Center',
  },
  {
    id: 3,
    vehicleName: '2023 Toyota Camry',
    driver: 'Mike Johnson',
    position: { lat: 40.7549, lng: -73.9840 },
    status: 'active',
    lastUpdate: '1 minute ago',
    speed: '30 mph',
    destination: 'JFK Airport',
  },
];