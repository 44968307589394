import React, { useRef, useEffect } from 'react';
import { VehicleLocation } from '../../types/mapping';
import { useMapInitialization } from './hooks/useMapInitialization';
import { usePopupManager } from './hooks/usePopupManager';

declare const atlas: any;

interface MapViewProps {
  vehicles: VehicleLocation[];
  selectedVehicle: VehicleLocation | null;
  onVehicleSelect: (vehicle: VehicleLocation) => void;
  onInfoWindowClose: () => void;
}

export default function MapView({
  vehicles,
  selectedVehicle,
  onVehicleSelect,
  onInfoWindowClose,
}: MapViewProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<any>(null);
  const isMapReady = useMapInitialization(mapRef, mapInstance);
  const { showPopup, hidePopup } = usePopupManager(mapInstance.current);

  useEffect(() => {
    if (!mapInstance.current || !isMapReady.current) return;

    const map = mapInstance.current;
    map.sources.clear();
    map.layers.clear();

    const datasource = new atlas.source.DataSource();
    map.sources.add(datasource);

    vehicles.forEach(vehicle => {
      const point = new atlas.Shape(new atlas.data.Point([
        vehicle.position.lng,
        vehicle.position.lat
      ]));
      point.addProperty('vehicle', vehicle);
      datasource.add(point);
    });

    const layer = new atlas.layer.SymbolLayer(datasource, null, {
      iconOptions: {
        image: 'marker-red',
        allowOverlap: true,
        size: 0.8
      }
    });

    map.layers.add(layer);

    map.events.add('click', layer, (e: any) => {
      if (e.shapes && e.shapes[0]) {
        const vehicle = e.shapes[0].getProperties().vehicle;
        onVehicleSelect(vehicle);
        showPopup(vehicle);
      }
    });

    return () => {
      hidePopup();
    };
  }, [vehicles, showPopup, hidePopup, onVehicleSelect, isMapReady]);

  useEffect(() => {
    if (!mapInstance.current || !isMapReady.current) return;
    
    if (selectedVehicle) {
      showPopup(selectedVehicle);
    } else {
      hidePopup();
    }
  }, [selectedVehicle, showPopup, hidePopup, isMapReady]);

  return (
    <div className="lg:col-span-3 bg-white rounded-lg shadow-sm p-4">
      <div ref={mapRef} style={{ width: '100%', height: '70vh' }} />
    </div>
  );
}