import React, { useState } from 'react';
import { format } from 'date-fns';
import { Car, Calendar, Shield, AlertTriangle, Wrench } from 'lucide-react';
import ServiceHistory from './ServiceHistory';

interface VehicleDetailsProps {
  vehicle: {
    id: number;
    make: string;
    model: string;
    year: number;
    registrationDate: string;
    maintenanceDate: string;
    insuranceDate: string;
    status: string;
    vin: string;
  };
}

export default function VehicleDetails({ vehicle }: VehicleDetailsProps) {
  const [activeTab, setActiveTab] = useState<'details' | 'service'>('details');

  const InfoCard = ({ icon: Icon, title, value, date = false }: any) => (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
      <div className="flex items-center mb-2">
        <Icon className="w-5 h-5 text-gray-400 mr-2" />
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      </div>
      <p className="text-lg font-semibold text-gray-900">
        {date ? format(new Date(value), 'MMM d, yyyy') : value}
      </p>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Vehicle Title */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Car className="w-8 h-8 text-blue-600" />
          <h1 className="text-2xl font-bold text-gray-900">
            {vehicle.year} {vehicle.make} {vehicle.model}
          </h1>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('details')}
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'details'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Vehicle Details
          </button>
          <button
            onClick={() => setActiveTab('service')}
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'service'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Service History
          </button>
        </nav>
      </div>

      {/* Tab Content */}
      <div className={activeTab === 'details' ? 'block' : 'hidden'}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <InfoCard icon={Car} title="VIN" value={vehicle.vin} />
          <InfoCard
            icon={Calendar}
            title="Registration Date"
            value={vehicle.registrationDate}
            date
          />
          <InfoCard
            icon={Wrench}
            title="Next Maintenance"
            value={vehicle.maintenanceDate}
            date
          />
          <InfoCard
            icon={Shield}
            title="Insurance Expiry"
            value={vehicle.insuranceDate}
            date
          />
          <InfoCard
            icon={AlertTriangle}
            title="Status"
            value={vehicle.status.charAt(0).toUpperCase() + vehicle.status.slice(1)}
          />
        </div>
      </div>

      <div className={activeTab === 'service' ? 'block' : 'hidden'}>
        <ServiceHistory vehicleId={vehicle.id} />
      </div>
    </div>
  );
}