import { useEffect, useRef, MutableRefObject } from 'react';

declare const atlas: any;

export function useMapInitialization(
  mapRef: MutableRefObject<HTMLDivElement | null>,
  mapInstance: MutableRefObject<any>
) {
  const isMapReady = useRef(false);

  useEffect(() => {
    if (!mapRef.current || mapInstance.current) return;

    mapInstance.current = new atlas.Map(mapRef.current, {
      authOptions: {
        authType: 'subscriptionKey',
        subscriptionKey: import.meta.env.VITE_AZURE_MAPS_KEY
      },
      center: [-74.0060, 40.7128],
      zoom: 12,
      style: 'road',
      language: 'en-US',
      view: 'Auto'
    });

    mapInstance.current.events.add('ready', () => {
      isMapReady.current = true;
    });

    return () => {
      if (mapInstance.current) {
        mapInstance.current.dispose();
      }
    };
  }, []);

  return isMapReady;
}