import React from 'react';
import { useForm } from 'react-hook-form';
import { Car } from 'lucide-react';

interface VehicleFormData {
  make: string;
  model: string;
  year: number;
  registrationDate: string;
  maintenanceDate: string;
  insuranceDate: string;
  tireChangeDate: string;
  servicingDate: string;
  vin: string;
}

interface VehicleFormProps {
  onSubmit: (data: VehicleFormData) => void;
  initialData?: Partial<VehicleFormData>;
  isEditing?: boolean;
}

export default function VehicleForm({ onSubmit, initialData, isEditing }: VehicleFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<VehicleFormData>({
    defaultValues: {
      year: new Date().getFullYear(),
      ...initialData,
    },
  });

  // Generate years array from current year down to 1900
  const years = Array.from(
    { length: new Date().getFullYear() - 1900 + 1 },
    (_, i) => new Date().getFullYear() - i
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center mb-6">
          <Car className="w-6 h-6 text-blue-600 mr-2" />
          <h2 className="text-xl font-semibold text-gray-900">
            {isEditing ? 'Edit Vehicle Details' : 'Add New Vehicle'}
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Make</label>
            <input
              type="text"
              {...register('make', { required: 'Make is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.make && (
              <p className="mt-1 text-sm text-red-600">{errors.make.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Model</label>
            <input
              type="text"
              {...register('model', { required: 'Model is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.model && (
              <p className="mt-1 text-sm text-red-600">{errors.model.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Year</label>
            <select
              {...register('year', { required: 'Year is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {errors.year && (
              <p className="mt-1 text-sm text-red-600">{errors.year.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">VIN</label>
            <input
              type="text"
              {...register('vin', { required: 'VIN is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.vin && (
              <p className="mt-1 text-sm text-red-600">{errors.vin.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Registration Date
            </label>
            <input
              type="date"
              {...register('registrationDate', { required: 'Registration date is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.registrationDate && (
              <p className="mt-1 text-sm text-red-600">{errors.registrationDate.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Next Maintenance Date
            </label>
            <input
              type="date"
              {...register('maintenanceDate', { required: 'Maintenance date is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.maintenanceDate && (
              <p className="mt-1 text-sm text-red-600">{errors.maintenanceDate.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Insurance Expiry Date
            </label>
            <input
              type="date"
              {...register('insuranceDate', { required: 'Insurance date is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.insuranceDate && (
              <p className="mt-1 text-sm text-red-600">{errors.insuranceDate.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Next Tire Change Date
            </label>
            <input
              type="date"
              {...register('tireChangeDate', { required: 'Tire change date is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.tireChangeDate && (
              <p className="mt-1 text-sm text-red-600">{errors.tireChangeDate.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Next Servicing Date
            </label>
            <input
              type="date"
              {...register('servicingDate', { required: 'Servicing date is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.servicingDate && (
              <p className="mt-1 text-sm text-red-600">{errors.servicingDate.message}</p>
            )}
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            {isSubmitting ? 'Saving...' : isEditing ? 'Update Vehicle' : 'Add Vehicle'}
          </button>
        </div>
      </div>
    </form>
  );
}