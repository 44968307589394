import React, { useState } from 'react';
import { BudgetPrediction } from '../../types/budget';

interface VehicleBudgetChartProps {
  predictions: BudgetPrediction[];
}

interface TooltipState {
  visible: boolean;
  content: string;
  x: number;
  y: number;
}

export default function VehicleBudgetChart({ predictions }: VehicleBudgetChartProps) {
  const [tooltip, setTooltip] = useState<TooltipState>({
    visible: false,
    content: '',
    x: 0,
    y: 0,
  });

  const categories = ['Fuel', 'Maintenance', 'Insurance', 'Depreciation'];
  const maxTotal = Math.max(...predictions.map(p => p.total));

  const handleMouseMove = (
    e: React.MouseEvent<HTMLDivElement>,
    category: string,
    value: number
  ) => {
    setTooltip({
      visible: true,
      content: `${category}: $${value.toLocaleString()}`,
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 relative">
      <h2 className="text-lg font-semibold mb-6">Monthly Cost Breakdown</h2>
      <div className="space-y-6">
        {predictions.map((prediction, index) => (
          <div key={index} className="space-y-2">
            <div className="flex justify-between items-center text-sm">
              <span className="font-medium">{prediction.month}</span>
              <span className="text-gray-500">
                ${prediction.total.toLocaleString()}
              </span>
            </div>
            <div className="h-6 bg-gray-100 rounded-full overflow-hidden">
              <div className="h-full flex">
                {categories.map((category, i) => {
                  const value = prediction[category.toLowerCase() as keyof BudgetPrediction] as number;
                  const width = (value / maxTotal) * 100;
                  const colors = [
                    'bg-blue-500',
                    'bg-green-500',
                    'bg-yellow-500',
                    'bg-purple-500'
                  ];
                  
                  return (
                    <div
                      key={i}
                      className={`${colors[i]} transition-all duration-200 hover:brightness-110`}
                      style={{ width: `${width}%` }}
                      onMouseMove={(e) => handleMouseMove(e, category, value)}
                      onMouseLeave={handleMouseLeave}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6 flex items-center justify-center space-x-6">
        {categories.map((category, i) => (
          <div key={i} className="flex items-center">
            <div
              className={`w-3 h-3 rounded-full mr-2 ${
                ['bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500'][i]
              }`}
            />
            <span className="text-sm text-gray-600">{category}</span>
          </div>
        ))}
      </div>

      {/* Tooltip */}
      {tooltip.visible && (
        <div
          className="fixed z-50 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm pointer-events-none"
          style={{
            left: `${tooltip.x + 10}px`, // Offset slightly from cursor
            top: `${tooltip.y - 30}px`, // Position above cursor
          }}
        >
          {tooltip.content}
          <div className="absolute w-2 h-2 bg-gray-900 rotate-45 -bottom-1 left-1/2 -translate-x-1/2" />
        </div>
      )}
    </div>
  );
}