import { VehicleCost, BudgetPrediction, VehicleBudgetSummary } from '../types/budget';
import { addMonths, format } from 'date-fns';

export const mockVehicleCosts: VehicleCost[] = [
  {
    vehicleId: 1,
    vehicleName: '2022 Tesla Model 3',
    monthlyFuel: 150,
    monthlyMaintenance: 100,
    monthlyInsurance: 200,
    monthlyDepreciation: 300,
    registrationFee: 120,
    inspectionFee: 50
  },
  {
    vehicleId: 2,
    vehicleName: '2021 Ford F-150',
    monthlyFuel: 300,
    monthlyMaintenance: 150,
    monthlyInsurance: 180,
    monthlyDepreciation: 250,
    registrationFee: 150,
    inspectionFee: 50
  }
];

export function generateBudgetPredictions(vehicleCost: VehicleCost): BudgetPrediction[] {
  const predictions: BudgetPrediction[] = [];
  const startDate = new Date();

  for (let i = 0; i < 12; i++) {
    const month = format(addMonths(startDate, i), 'MMM yyyy');
    const maintenanceSpike = i % 3 === 2 ? vehicleCost.monthlyMaintenance * 2 : vehicleCost.monthlyMaintenance;
    
    predictions.push({
      month,
      fuel: vehicleCost.monthlyFuel,
      maintenance: maintenanceSpike,
      insurance: vehicleCost.monthlyInsurance,
      depreciation: vehicleCost.monthlyDepreciation,
      total: vehicleCost.monthlyFuel + maintenanceSpike + 
             vehicleCost.monthlyInsurance + vehicleCost.monthlyDepreciation +
             (i === 0 ? vehicleCost.registrationFee + vehicleCost.inspectionFee : 0)
    });
  }

  return predictions;
}

export function generateVehicleBudgetSummary(vehicleCost: VehicleCost): VehicleBudgetSummary {
  const predictions = generateBudgetPredictions(vehicleCost);
  const annualTotal = predictions.reduce((sum, month) => sum + month.total, 0);

  return {
    vehicleId: vehicleCost.vehicleId,
    vehicleName: vehicleCost.vehicleName,
    annualTotal,
    monthlyAverage: annualTotal / 12,
    predictions
  };
}