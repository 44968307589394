import React, { useState } from 'react';
import { Navigation } from 'lucide-react';
import VehicleList from '../components/mapping/VehicleList';
import MapView from '../components/mapping/MapView';
import { VehicleLocation } from '../types/mapping';
import { mockVehicleLocations } from '../data/mockVehicleLocations';

export default function Mapping() {
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleLocation | null>(null);
  const [mapView, setMapView] = useState<'all' | 'active'>('all');

  const filteredVehicles = mapView === 'all' 
    ? mockVehicleLocations 
    : mockVehicleLocations.filter(v => v.status === 'active');

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Fleet Tracking</h1>
        <div className="flex space-x-4">
          <select
            value={mapView}
            onChange={(e) => setMapView(e.target.value as 'all' | 'active')}
            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="all">All Vehicles</option>
            <option value="active">Active Only</option>
          </select>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center">
            <Navigation className="w-4 h-4 mr-2" />
            Route Planner
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <VehicleList
          vehicles={filteredVehicles}
          selectedVehicle={selectedVehicle}
          onVehicleSelect={setSelectedVehicle}
        />
        <MapView
          vehicles={filteredVehicles}
          selectedVehicle={selectedVehicle}
          onVehicleSelect={setSelectedVehicle}
          onInfoWindowClose={() => setSelectedVehicle(null)}
        />
      </div>
    </div>
  );
}