import React, { useState } from 'react';
import { DollarSign } from 'lucide-react';
import BudgetSummary from '../components/budgets/BudgetSummary';
import VehicleBudgetChart from '../components/budgets/VehicleBudgetChart';
import ExportModal from '../components/budgets/ExportModal';
import { mockVehicleCosts, generateVehicleBudgetSummary } from '../data/mockBudgetData';
import { VehicleBudgetSummary } from '../types/budget';
import { exportToCSV, exportToPDF } from '../utils/exportBudget';
import { toast } from 'react-hot-toast';

export default function Budgets() {
  const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const budgetSummaries: VehicleBudgetSummary[] = mockVehicleCosts.map(generateVehicleBudgetSummary);
  const selectedSummary = budgetSummaries.find(s => s.vehicleId === selectedVehicleId);

  const handleExport = (format: 'csv' | 'pdf') => {
    try {
      if (format === 'csv') {
        exportToCSV(budgetSummaries);
        toast.success('Budget report exported to CSV');
      } else {
        exportToPDF(budgetSummaries);
        toast.success('Budget report exported to PDF');
      }
    } catch (error) {
      toast.error('Failed to export budget report');
    } finally {
      setShowExportModal(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Budget Forecasting</h1>
        <button 
          onClick={() => setShowExportModal(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
        >
          <DollarSign className="w-4 h-4 mr-2" />
          Export Report
        </button>
      </div>

      <BudgetSummary summaries={budgetSummaries} />

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-1 bg-white rounded-lg shadow-sm p-4">
          <h2 className="text-lg font-semibold mb-4">Vehicles</h2>
          <div className="space-y-4">
            {budgetSummaries.map((summary) => (
              <div
                key={summary.vehicleId}
                className={`p-4 rounded-lg border cursor-pointer transition-colors ${
                  selectedVehicleId === summary.vehicleId
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:bg-gray-50'
                }`}
                onClick={() => setSelectedVehicleId(summary.vehicleId)}
              >
                <h3 className="font-medium text-gray-900">{summary.vehicleName}</h3>
                <div className="mt-2 space-y-1">
                  <p className="text-sm text-gray-600">
                    Annual: ${summary.annualTotal.toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-600">
                    Monthly Avg: ${summary.monthlyAverage.toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:col-span-3">
          {selectedSummary ? (
            <VehicleBudgetChart predictions={selectedSummary.predictions} />
          ) : (
            <div className="bg-white rounded-lg shadow-sm p-6 text-center text-gray-500">
              Select a vehicle to view detailed cost predictions
            </div>
          )}
        </div>
      </div>

      <ExportModal
        isOpen={showExportModal}
        onClose={() => setShowExportModal(false)}
        onExport={handleExport}
      />
    </div>
  );
}