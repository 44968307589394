import React from 'react';

interface VehicleStatusBadgeProps {
  status: string;
}

export default function VehicleStatusBadge({ status }: VehicleStatusBadgeProps) {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'maintenance':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <span
      className={`px-2 py-1 text-xs font-medium rounded-full ${getStatusColor(status)}`}
    >
      {status}
    </span>
  );
}