import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DriverList, { Driver } from '../components/drivers/DriverList';
import DriverForm from '../components/drivers/DriverForm';
import { toast } from 'react-hot-toast';

// Mock data - replace with API calls
const mockDrivers: Driver[] = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    licenseNumber: 'DL123456',
    licenseExpirationDate: '2024-12-31',
    licenseImageUrl: 'https://images.unsplash.com/photo-1533738363-b7f9aef128ce?auto=format&fit=crop&w=300&h=200',
    status: 'active',
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    licenseNumber: 'DL789012',
    licenseExpirationDate: '2024-06-15',
    licenseImageUrl: 'https://images.unsplash.com/photo-1542282088-72c9c27ed0cd?auto=format&fit=crop&w=300&h=200',
    status: 'expiring',
  },
];

export default function Drivers() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [drivers] = useState<Driver[]>(mockDrivers);
  const isAddMode = id === 'add';
  const isEditMode = id && !isAddMode;

  const handleSubmit = async (data: any) => {
    try {
      if (isAddMode) {
        // Add new driver
        toast.success('Driver added successfully');
        navigate('/drivers');
      } else if (isEditMode) {
        // Update existing driver
        toast.success('Driver updated successfully');
        navigate('/drivers');
      }
    } catch (error) {
      toast.error('Failed to save driver details');
    }
  };

  // Show form for add/edit
  if (isAddMode || isEditMode) {
    const driver = isEditMode ? drivers.find((d) => d.id === Number(id)) : undefined;

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">
            {isAddMode ? 'Add New Driver' : 'Edit Driver'}
          </h1>
          <button
            onClick={() => navigate('/drivers')}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            Back to List
          </button>
        </div>
        <DriverForm
          onSubmit={handleSubmit}
          initialData={driver}
          isEditing={isEditMode}
        />
      </div>
    );
  }

  // Show driver list
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Drivers</h1>
      <DriverList drivers={drivers} />
    </div>
  );
}