import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Shield, Upload, X } from 'lucide-react';

interface InsuranceFormData {
  vehicleId: number;
  provider: string;
  policyNumber: string;
  startDate: string;
  expirationDate: string;
  coverageDetails: string;
}

interface InsuranceFormProps {
  onSubmit: (data: InsuranceFormData) => void;
  onCancel: () => void;
  vehicles: Array<{ id: number; name: string }>;
  initialData?: Partial<InsuranceFormData>;
}

export default function InsuranceForm({
  onSubmit,
  onCancel,
  vehicles,
  initialData,
}: InsuranceFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<InsuranceFormData>({
    defaultValues: {
      startDate: new Date().toISOString().split('T')[0],
      ...initialData,
    },
  });

  const [policyDocument, setPolicyDocument] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setPolicyDocument(file);
    }
  };

  const removeDocument = () => {
    setPolicyDocument(null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center mb-6">
          <Shield className="w-6 h-6 text-blue-600 mr-2" />
          <h2 className="text-xl font-semibold text-gray-900">
            {initialData ? 'Edit Insurance Policy' : 'Add New Insurance Policy'}
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Vehicle</label>
            <select
              {...register('vehicleId', { required: 'Vehicle is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Select a vehicle</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.name}
                </option>
              ))}
            </select>
            {errors.vehicleId && (
              <p className="mt-1 text-sm text-red-600">{errors.vehicleId.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Insurance Provider
            </label>
            <input
              type="text"
              {...register('provider', { required: 'Provider is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.provider && (
              <p className="mt-1 text-sm text-red-600">{errors.provider.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Policy Number
            </label>
            <input
              type="text"
              {...register('policyNumber', { required: 'Policy number is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.policyNumber && (
              <p className="mt-1 text-sm text-red-600">{errors.policyNumber.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              {...register('startDate', { required: 'Start date is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.startDate && (
              <p className="mt-1 text-sm text-red-600">{errors.startDate.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Expiration Date
            </label>
            <input
              type="date"
              {...register('expirationDate', { required: 'Expiration date is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.expirationDate && (
              <p className="mt-1 text-sm text-red-600">
                {errors.expirationDate.message}
              </p>
            )}
          </div>

          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Coverage Details
            </label>
            <textarea
              {...register('coverageDetails', { required: 'Coverage details are required' })}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter coverage details, limits, and any special conditions..."
            />
            {errors.coverageDetails && (
              <p className="mt-1 text-sm text-red-600">
                {errors.coverageDetails.message}
              </p>
            )}
          </div>

          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Policy Document
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                {policyDocument ? (
                  <div className="flex items-center justify-center space-x-2">
                    <span className="text-sm text-gray-600">{policyDocument.name}</span>
                    <button
                      type="button"
                      onClick={removeDocument}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                ) : (
                  <>
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                        <span>Upload a file</span>
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          onChange={handleFileChange}
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PDF, PNG, JPG up to 10MB</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            {isSubmitting
              ? 'Saving...'
              : initialData
              ? 'Update Policy'
              : 'Add Policy'}
          </button>
        </div>
      </div>
    </form>
  );
}