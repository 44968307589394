import React from 'react';
import { VehicleBudgetSummary } from '../../types/budget';
import { DollarSign, TrendingUp, Calendar } from 'lucide-react';

interface BudgetSummaryProps {
  summaries: VehicleBudgetSummary[];
}

export default function BudgetSummary({ summaries }: BudgetSummaryProps) {
  const totalAnnual = summaries.reduce((sum, vehicle) => sum + vehicle.annualTotal, 0);
  const averageMonthly = totalAnnual / 12;

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100">
        <div className="flex items-center">
          <div className="p-2 bg-blue-50 rounded-lg">
            <DollarSign className="w-6 h-6 text-blue-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">Annual Budget</p>
            <p className="text-2xl font-semibold text-gray-900">
              ${totalAnnual.toLocaleString()}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100">
        <div className="flex items-center">
          <div className="p-2 bg-green-50 rounded-lg">
            <Calendar className="w-6 h-6 text-green-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">Monthly Average</p>
            <p className="text-2xl font-semibold text-gray-900">
              ${averageMonthly.toLocaleString()}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100">
        <div className="flex items-center">
          <div className="p-2 bg-purple-50 rounded-lg">
            <TrendingUp className="w-6 h-6 text-purple-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">Vehicles Tracked</p>
            <p className="text-2xl font-semibold text-gray-900">
              {summaries.length}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}