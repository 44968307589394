import React from 'react';
import { FileText, FileSpreadsheet, X } from 'lucide-react';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onExport: (format: 'csv' | 'pdf') => void;
}

export default function ExportModal({ isOpen, onClose, onExport }: ExportModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold text-gray-900">Export Budget Report</h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => onExport('csv')}
              className="flex flex-col items-center p-6 border rounded-lg hover:bg-gray-50 transition-colors"
            >
              <FileSpreadsheet className="w-12 h-12 text-green-600 mb-3" />
              <span className="font-medium text-gray-900">CSV Format</span>
              <span className="text-sm text-gray-500 mt-1">Spreadsheet compatible</span>
            </button>

            <button
              onClick={() => onExport('pdf')}
              className="flex flex-col items-center p-6 border rounded-lg hover:bg-gray-50 transition-colors"
            >
              <FileText className="w-12 h-12 text-red-600 mb-3" />
              <span className="font-medium text-gray-900">PDF Format</span>
              <span className="text-sm text-gray-500 mt-1">Print ready report</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}