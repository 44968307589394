import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InsuranceList, { InsurancePolicy } from '../components/insurance/InsuranceList';
import InsuranceForm from '../components/insurance/InsuranceForm';
import { toast } from 'react-hot-toast';

// Mock data - replace with API calls
const mockInsurancePolicies: InsurancePolicy[] = [
  {
    id: 1,
    vehicleId: 1,
    vehicleName: '2022 Tesla Model 3',
    provider: 'SafeDrive Insurance',
    policyNumber: 'POL-123456',
    startDate: '2024-01-01',
    expirationDate: '2024-12-31',
    coverageDetails: 'Comprehensive coverage with roadside assistance',
    documentUrl: 'https://example.com/policy1.pdf',
    status: 'active',
  },
  {
    id: 2,
    vehicleId: 2,
    vehicleName: '2021 Ford F-150',
    provider: 'SecureAuto Insurance',
    policyNumber: 'POL-789012',
    startDate: '2024-01-15',
    expirationDate: '2024-03-25',
    coverageDetails: 'Basic coverage',
    documentUrl: 'https://example.com/policy2.pdf',
    status: 'expiring',
  },
];

const mockVehicles = [
  { id: 1, name: '2022 Tesla Model 3' },
  { id: 2, name: '2021 Ford F-150' },
];

export default function InsurancePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [policies] = useState<InsurancePolicy[]>(mockInsurancePolicies);
  const isAddMode = id === 'add';
  const isEditMode = id && !isAddMode;

  const handleSubmit = async (data: any) => {
    try {
      if (isAddMode) {
        // Add new insurance policy
        toast.success('Insurance policy added successfully');
        navigate('/insurance');
      } else if (isEditMode) {
        // Update existing policy
        toast.success('Insurance policy updated successfully');
        navigate('/insurance');
      }
    } catch (error) {
      toast.error('Failed to save insurance policy');
    }
  };

  // Show form for add/edit
  if (isAddMode || isEditMode) {
    const policy = isEditMode
      ? policies.find((p) => p.id === Number(id))
      : undefined;

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">
            {isAddMode ? 'Add New Insurance Policy' : 'Edit Insurance Policy'}
          </h1>
          <button
            onClick={() => navigate('/insurance')}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            Back to List
          </button>
        </div>
        <InsuranceForm
          onSubmit={handleSubmit}
          onCancel={() => navigate('/insurance')}
          vehicles={mockVehicles}
          initialData={policy}
        />
      </div>
    );
  }

  // Show insurance list
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Insurance Management</h1>
      <InsuranceList policies={policies} />
    </div>
  );
}