import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Car, Shield, Wrench, Users } from 'lucide-react';

export default function Welcome() {
  const navigate = useNavigate();

  const features = [
    {
      icon: Car,
      title: 'Vehicle Management',
      description: 'Track and manage your entire fleet in one place',
    },
    {
      icon: Wrench,
      title: 'Maintenance Tracking',
      description: 'Never miss a service appointment or maintenance check',
    },
    {
      icon: Shield,
      title: 'Insurance Management',
      description: 'Keep insurance policies up to date and organized',
    },
    {
      icon: Users,
      title: 'Driver Management',
      description: 'Manage driver information and license renewals',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-20 pb-12 md:pt-40 md:pb-20">
          {/* Hero Section */}
          <div className="text-center">
            <div className="flex justify-center mb-8">
              <Car className="w-16 h-16 text-blue-600" />
            </div>
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Fleet Manager</span>
              <span className="block text-blue-600">Simplified Vehicle Management</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Streamline your fleet operations with our comprehensive vehicle management system.
              Track maintenance, manage insurance, and keep your fleet running smoothly.
            </p>
            <div className="mt-10 flex justify-center gap-4">
              <button
                onClick={() => navigate('/sign-in')}
                className="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:text-lg"
              >
                Sign In
              </button>
              <button
                onClick={() => navigate('/sign-up')}
                className="px-8 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 md:text-lg"
              >
                Sign Up
              </button>
            </div>
          </div>

          {/* Features Section */}
          <div className="mt-32">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div
                    key={index}
                    className="relative bg-white p-6 rounded-lg shadow-sm border border-gray-100"
                  >
                    <div className="w-12 h-12 rounded-lg bg-blue-50 flex items-center justify-center mb-4">
                      <Icon className="w-6 h-6 text-blue-600" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}