import { useEffect, useRef } from 'react';
import { VehicleLocation } from '../../../types/mapping';

declare const atlas: any;

export function usePopupManager(map: any) {
  const popup = useRef<any>(null);

  useEffect(() => {
    if (!map) return;

    map.events.addOnce('ready', () => {
      popup.current = new atlas.Popup({
        pixelOffset: [0, -30],
        closeButton: true
      });
    });

    return () => {
      if (popup.current) {
        popup.current.close();
      }
    };
  }, [map]);

  const showPopup = (vehicle: VehicleLocation) => {
    if (!map || !popup.current) return;

    popup.current.setOptions({
      content: `
        <div class="p-4">
          <h3 class="font-medium text-gray-900">${vehicle.vehicleName}</h3>
          <p class="text-sm text-gray-600">Driver: ${vehicle.driver}</p>
          <p class="text-sm text-gray-600">Speed: ${vehicle.speed}</p>
          <p class="text-sm text-gray-600">Destination: ${vehicle.destination}</p>
          <p class="text-sm text-gray-600">Last Update: ${vehicle.lastUpdate}</p>
        </div>
      `,
      position: [vehicle.position.lng, vehicle.position.lat]
    });

    popup.current.open(map);
  };

  const hidePopup = () => {
    if (popup.current) {
      popup.current.close();
    }
  };

  return { showPopup, hidePopup };
}