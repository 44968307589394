import { VehicleBudgetSummary } from '../types/budget';
import { format } from 'date-fns';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import BudgetPDF from '../components/budgets/BudgetPDF';
import { createElement } from 'react';

export function exportToCSV(summaries: VehicleBudgetSummary[]) {
  const headers = [
    'Vehicle',
    'Annual Total',
    'Monthly Average',
    ...Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`)
  ].join(',');

  const rows = summaries.map(summary => {
    const monthlyTotals = summary.predictions.map(p => p.total);
    return [
      `"${summary.vehicleName}"`,
      summary.annualTotal,
      summary.monthlyAverage,
      ...monthlyTotals
    ].join(',');
  });

  const csv = [headers, ...rows].join('\n');
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `budget_report_${format(new Date(), 'yyyy-MM-dd')}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export async function exportToPDF(summaries: VehicleBudgetSummary[]) {
  try {
    // Create PDF document using createElement instead of JSX
    const doc = await pdf(
      createElement(BudgetPDF, { summaries })
    ).toBlob();
    
    const url = URL.createObjectURL(doc);
    const link = document.createElement('a');
    link.href = url;
    link.download = `budget_forecast_${format(new Date(), 'yyyy-MM-dd')}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
}