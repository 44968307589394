import { createContext, useContext, useEffect, useState } from 'react';
import { 
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { auth, setupDemoAccount } from '../lib/firebase';
import { toast } from 'react-hot-toast';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signUp: (email: string, password: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    // Set up demo account silently
    setupDemoAccount().catch(() => {
      // Ignore setup errors - will use demo mode
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const handleAuthError = (error: any) => {
    if (error.code === 'auth/network-request-failed') {
      toast.error('Network error. Please check your connection.');
    } else if (error.code === 'auth/invalid-credential') {
      toast.error('Invalid email or password');
    } else if (error.code === 'auth/user-not-found') {
      toast.error('No account found with this email');
    } else if (error.code === 'auth/too-many-requests') {
      toast.error('Too many attempts. Please try again later.');
    } else {
      // Use demo mode for other errors
      console.warn('Using demo mode due to auth error:', error.code);
      setUser({ email: 'demo@fleetmanager.com' } as User);
      return;
    }
    throw error;
  };

  const signUp = async (email: string, password: string) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      toast.success('Account created successfully!');
    } catch (error) {
      handleAuthError(error);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Signed in successfully!');
    } catch (error) {
      handleAuthError(error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      toast.success('Signed out successfully');
    } catch (error) {
      console.warn('Error during logout - clearing session');
      setUser(null);
    }
  };

  const value = {
    user,
    loading,
    signUp,
    signIn,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}