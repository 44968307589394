import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getEnvVar } from './env';

// Demo configuration for development
const firebaseConfig = {
  apiKey: "AIzaSyBR3-JE6Xt1vqhQrX4lV8PQxOzQHmKt-Xo",
  authDomain: "fleet-manager-demo.firebaseapp.com",
  projectId: "fleet-manager-demo",
  storageBucket: "fleet-manager-demo.appspot.com",
  messagingSenderId: "901234567890",
  appId: "1:901234567890:web:abc123def456ghi789"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const setupDemoAccount = async () => {
  if (!auth) return;

  try {
    const { signInWithEmailAndPassword, createUserWithEmailAndPassword } = await import('firebase/auth');
    const { DEMO_EMAIL, DEMO_PASSWORD } = await import('../pages/SignIn');
    
    try {
      await signInWithEmailAndPassword(auth, DEMO_EMAIL, DEMO_PASSWORD);
      console.log('Demo account signed in successfully');
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        try {
          await createUserWithEmailAndPassword(auth, DEMO_EMAIL, DEMO_PASSWORD);
          console.log('Demo account created successfully');
        } catch (createError: any) {
          if (createError.code !== 'auth/email-already-in-use') {
            console.error('Error creating demo account:', createError);
          }
        }
      } else if (error.code === 'auth/network-request-failed') {
        console.log('Network error while setting up demo account - will retry on next load');
      } else if (error.code === 'auth/invalid-credential') {
        console.log('Invalid credentials - using demo mode');
      } else {
        console.warn('Non-critical auth error:', error.code);
      }
    }
  } catch (error) {
    console.warn('Firebase auth module import failed - running in demo mode');
  }
};