import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Car, AlertTriangle, FileCheck } from 'lucide-react';
import { differenceInDays } from 'date-fns';
import VehicleList, { Vehicle } from '../components/vehicles/VehicleList';
import VehicleForm from '../components/vehicles/VehicleForm';
import VehicleDetails from '../components/vehicles/VehicleDetails';
import { toast } from 'react-hot-toast';

// Mock data - replace with API calls
const mockVehicles: Vehicle[] = [
  {
    id: 1,
    make: 'Tesla',
    model: 'Model 3',
    year: 2022,
    registrationDate: '2024-12-31',
    maintenanceDate: '2024-03-25',
    insuranceDate: '2024-06-15',
    status: 'active',
    vin: '1HGCM82633A123456',
  },
  {
    id: 2,
    make: 'Ford',
    model: 'F-150',
    year: 2021,
    registrationDate: '2024-04-15', // Due soon
    maintenanceDate: '2024-04-10',
    insuranceDate: '2024-09-30',
    status: 'maintenance',
    vin: '2FMZA52234B789012',
  },
  {
    id: 3,
    make: 'Toyota',
    model: 'Camry',
    year: 2023,
    registrationDate: '2025-01-15',
    maintenanceDate: '2024-04-05', // Due soon
    insuranceDate: '2024-08-20',
    status: 'active',
    vin: '3TMZA52234B789013',
  },
];

export default function Vehicles() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicles] = useState<Vehicle[]>(mockVehicles);
  const isAddMode = id === 'add';

  // Calculate summary metrics
  const totalVehicles = vehicles.length;
  const today = new Date();
  
  const registrationsDue = vehicles.filter(vehicle => {
    const daysUntilRegistration = differenceInDays(
      new Date(vehicle.registrationDate),
      today
    );
    return daysUntilRegistration >= 0 && daysUntilRegistration <= 60;
  }).length;

  const servicingDue = vehicles.filter(vehicle => {
    const daysUntilService = differenceInDays(
      new Date(vehicle.maintenanceDate),
      today
    );
    return daysUntilService >= 0 && daysUntilService <= 60;
  }).length;

  const summaryCards = [
    {
      title: 'Total Vehicles',
      value: totalVehicles,
      icon: Car,
      color: 'text-blue-600',
      bgColor: 'bg-blue-50',
    },
    {
      title: 'Registration Due',
      subtitle: 'Next 60 days',
      value: registrationsDue,
      icon: FileCheck,
      color: 'text-amber-600',
      bgColor: 'bg-amber-50',
    },
    {
      title: 'Service Due',
      subtitle: 'Next 60 days',
      value: servicingDue,
      icon: AlertTriangle,
      color: 'text-red-600',
      bgColor: 'bg-red-50',
    },
  ];

  const handleSubmit = async (data: any) => {
    try {
      if (isAddMode) {
        // Add new vehicle
        toast.success('Vehicle added successfully');
        navigate('/vehicles');
      } else if (id) {
        // Update existing vehicle
        toast.success('Vehicle updated successfully');
        navigate('/vehicles');
      }
    } catch (error) {
      toast.error('Failed to save vehicle details');
    }
  };

  // Show form for add mode
  if (isAddMode) {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Add New Vehicle</h1>
          <button
            onClick={() => navigate('/vehicles')}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            Back to List
          </button>
        </div>
        <VehicleForm onSubmit={handleSubmit} isEditing={false} />
      </div>
    );
  }

  // Show vehicle details
  if (id) {
    const vehicle = vehicles.find((v) => v.id === Number(id));
    if (!vehicle) {
      return <div>Vehicle not found</div>;
    }

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Vehicle Details</h1>
          <button
            onClick={() => navigate('/vehicles')}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            Back to List
          </button>
        </div>
        <VehicleDetails vehicle={vehicle} />
      </div>
    );
  }

  // Show vehicle list with summary cards
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Vehicles</h1>
      
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {summaryCards.map((card, index) => {
          const Icon = card.icon;
          return (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm p-6 border border-gray-100"
            >
              <div className="flex items-center">
                <div className={`p-3 rounded-lg ${card.bgColor}`}>
                  <Icon className={`w-6 h-6 ${card.color}`} />
                </div>
                <div className="ml-4">
                  <h3 className="text-sm font-medium text-gray-500">{card.title}</h3>
                  {card.subtitle && (
                    <p className="text-xs text-gray-400">{card.subtitle}</p>
                  )}
                  <p className="mt-1 text-2xl font-semibold text-gray-900">
                    {card.value}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <VehicleList vehicles={vehicles} />
    </div>
  );
}